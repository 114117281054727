import styled from 'styled-components';
import { Routes, Route } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import Home from './Home';
import ORings from './ORings';
import Footer from './Footer';
import Header from './Header';


const useDesktopMediaQuery = () =>
  useMediaQuery({ query: '(min-width: 768px)' })

const useMobileMediaQuery = () =>
  useMediaQuery({ query: '(max-width: 767px)' })

const Desktop = ({ children }) => {
  const isDesktop = useDesktopMediaQuery();
  return isDesktop ? children : null;
};

const Mobile = ({ children }) => {
  const isTabletAndBelow = useMobileMediaQuery();
  return isTabletAndBelow ? children : null;
};

const StyledDesktopMain = styled.main`
  margin: 0 auto 4em;
  width: 30rem;
`;

const StyledMobileMain = styled.main`
  margin: 0 auto 4em;
  width: 20rem;
`;

const StyledOuterWrap = styled.div`
  position: relative;
  margin: 0 1em 0
`;

const StyledInnerWrap = styled.div`
  position: relative;
  width: 100%;
`;

const InnerWrap = (props) => {
  return (
    <StyledInnerWrap>
      <Header></Header>
      <Routes>
        <Route exact path='/' element={Home()}></Route>
        <Route exact path='/o-rings' element={ORings()}></Route>
      </Routes>
      <Footer></Footer>
    </StyledInnerWrap>
  );
};

const Main = (props) => {
  return (
    <StyledOuterWrap>
      <Desktop>
        <StyledDesktopMain>
          <InnerWrap></InnerWrap>
        </StyledDesktopMain>
      </Desktop>
      <Mobile>
        <StyledMobileMain>
          <InnerWrap></InnerWrap>
        </StyledMobileMain>
      </Mobile>
    </StyledOuterWrap>
  );
};

export default Main;