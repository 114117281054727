
const ORingText = `
# Information about o-ring replacements

We permanently discontinued our wallets in 2022 so that we can 
focus more time on our musical instruments. While our wallets may 
well last a lifetime, the o-rings do have a tendency to eventually 
fail with use. While we no longer have o-rings in stock, if 
you're looking for replacements, here's what you should know. 

## Size
O-rings are sized in 'dash numbers' that indicate their thickness 
and diameter. The A3 and A4 both use a pair of –&#8288;227 o-rings. The 
A4 also uses a smaller –&#8288;120. If you tend to carry more items in your 
wallet, you might consider stepping up a size, say –&#8288;228 and –&#8288;121. 

## Hardness
Rubber hardness is referred to as 'durometer'. We recommend 50A 
o-rings. Most o-rings you'll find at the local hardware store are 
probably 70A, and this is a little too stiff for our wallets.

## Material
Again, most o-rings you'll find at the local hardware supplier 
are made from buna-nitrile rubber, which you may also see listed 
as NBR. We don't recommend this. The o-rings we shipped with our 
A4 wallet were made from urethane which is a much longer lasting 
rubber under the conditions that a wallet is typically subjected 
to. Silicone is another good alternative to NBR. Unfortunately, 
both urethane and silicone rings are much less common than NBR. 

## What to do
McMaster-Carr sells 50A silicone rings, both in 
[–&#8288;227](https://www.mcmaster.com/1173N227/) and 
[–&#8288;120](https://www.mcmaster.com/1173N12/), but they're not available 
in black. Instead they're a beautiful orange-red. Unless you're planning 
on manufacturing custom o-rings (which is what we did), these are 
probably your best bet. If you really want black rings, McMaster also 
sells [NBR 50A rings](https://www.mcmaster.com/products/o-rings/color~black/hardness~durometer-50a/dash-number~120/dash-number~227/).
`;

export default ORingText;