import {Fragment, useEffect} from 'react';
import Markdown from 'react-markdown'
import ReactGA from 'react-ga4';
import HomeText from './text/HomeText';


const Home = (props) => {

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/', title: 'Home' });
  }, [])

  return (
    <Fragment>
      <Markdown>{HomeText}</Markdown>
    </Fragment>
  );
};

export default Home;