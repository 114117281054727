import {useEffect} from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import ReactGA from 'react-ga4';
import GlobalStyle from './components/styles/GlobalStyle';
import Main from './components/Main';


const TRACKING_ID = 'G-36857392'

function App() {

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
  }, []);

  return (
    <Router>
      <GlobalStyle />
      <Main />
    </Router>
  );
}

export default App;