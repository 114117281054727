import styled from 'styled-components';
import {Link} from 'react-router-dom';
import LogoRight from './images/horiz_gray-cropped-right.svg';
import LogoLeft from './images/horiz_gray-cropped-left.svg';


const StyledH1 = styled.h1`
  position: relative;
  margin-bottom: 3em;
  width: 100%;
  height: 11.4%;
`;

const StyledLogoRight = styled.img`
  position: relative;
  width: 100%;
`;

const StyledLogoLeft = styled.img`
  position: absolute;
  width: 32.3%;
  right: 100%;
  top: 0;
`;

const H1Link = (props) => {
  return (
    <Link to="/">
      <StyledLogoRight src={LogoRight} alt="Obstructures" ></StyledLogoRight>
      <StyledLogoLeft src={LogoLeft} alt="Obstructures Logo" ></StyledLogoLeft>
    </Link>
  );
};

const Header = (props) => {
  return (
    <StyledH1>
      <H1Link></H1Link>
    </StyledH1>
  )
};

export default Header;