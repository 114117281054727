
const today = new Date();
const month = today.getMonth();
const year = today.getFullYear();


const IntroText = (year == 2025)
  ? (month <= 1)
    ? `A warm (belated) welcome to 2025 and the new [obstructures.org](https://obstructures.org). `
    : `Welcome to 2025 and the new [obstructures.org](https://obstructures.org). `
  : `Welcome [obstructures.org](https://obstructures.org). `

const HomeText = IntroText + `
Glad you're here. Let's keep things simple:

## Guitars
We're planning on releasing one or two runs of guitars a year
for the foreseeable future. Hit us up at 
[info@obstructures.org](mailto:info@obstructures.org) 
if you have questions or want to be added to the buyers'
list for an upcoming run. 

## Basses 
They're coming in 2025. Honestly.

## Pedals
If you're here about pedals, hit up our pals at 
[electronicaudioexperiments.com](https://electronicaudioexperiments.com).

## O-rings 
If you need to replace the o-rings on one of our wallets, [read this](/o-rings).

## Otherwise
We are still occupied primarily as professors in the respective
faculties of universities in Chicago IL and Auburn AL---so we tend towards
long-windedness despite our best efforts otherwise, and argue, debate, and
discuss each and every maneuver, no matter how big or small, that affects
the design, manufacture, and performance of these instruments. We are
really fucking slow, on purpose. Please bear with us.
`;

export default HomeText;