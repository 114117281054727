import styled from 'styled-components';
import Markdown from 'react-markdown';
import FooterText from './text/FooterText';


const StyledFooter = styled.footer`
  position: relative;
  margin-top: 4em;
  width: 100%;
  font-size: .85em;
`;

const StyledH3 = styled.h3`
  color: #767676;
`;

const StyledFooterLi = styled.li`
  list-style-type: none;
  line-height: 1em;
  margin: 0 0 .5em 0;
`;

const Footer = (props) => {
  return (
    <StyledFooter>
      <Markdown components={{
        li: StyledFooterLi,
      }}>{FooterText}</Markdown>
      <StyledH3>\\ \<br/>All content &copy; 2025 Obstructures LLC</StyledH3>
    </StyledFooter>
  );
};

export default Footer;