import { createGlobalStyle } from 'styled-components';
import 'typeface-ibm-plex-mono';


const GlobalStyle = createGlobalStyle`
* {
  padding: 0;
  margin: 0; }
body {
  background-color: #ffffff; }
h1, h2, h3, h4, h5, h6, p, li, cite, blockquote {
  color: #000000; }
@media (prefers-color-scheme: light) {
  body {
    background-color: #ffffff; }
  h1, h2, h3, h4, h5, h6, p, li, cite, blockquote {
    color: #000000; }
}
@media (prefers-color-scheme: dark) {
  body {
    background-color: #000000; }
  h1, h2, h3, h4, h5, h6, p, li, cite, blockquote {
    color: #dddddd; }
  h1 img {
    filter: invert(1); }
}
h1, h2, h3, h4, h5, h6, p, li, cite, blockquote {
  font-family: 'IBM Plex Mono', monospace;
  font-size: .85em; }
p, li {
  line-height: 1.33em; }
p {
  margin-bottom: 1em; }
li {
  margin-left: 1em; }
a {
  color: #ff7700; }
p a, li a {
  text-decoration: none;
  border-bottom-style: solid;
  border-bottom-width: 1px; }
`;

export default GlobalStyle;