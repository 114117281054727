import {Fragment, useEffect} from 'react';
import Markdown from 'react-markdown'
import ReactGA from 'react-ga4';
import ORingText from './text/ORingText';


const ORings = (props) => {

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/o-rings', title: 'O-Rings' });
  }, [])

  return (
    <Fragment>
      <Markdown>{ORingText}</Markdown>
    </Fragment>
  );
};

export default ORings;